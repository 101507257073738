import * as React from 'react';

function Eyes7(props) {
  const color = props.eyecolor;
  return (
    <>
      <g>
        <g>
          <circle cx={-422.5} cy={179.8} r={144.5} fill="#f4ece8" />
          <circle
            cx={-422.5}
            cy={179.8}
            r={119.5}
            fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
          />
          <circle cx={-422.6} cy={179.7} r={82.5} fill="#010205" />
          <circle cx={-352} cy={121.1} r={45.5} fill="#f4ece8" />
        </g>
        <g>
          <circle cx={177} cy={136} r={85} fill="#f4ece8" />
          <circle
            transform="rotate(-60.515 176.998 135.953)"
            cx={177}
            cy={136}
            fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
            r={58.1}
          />
          <circle
            transform="rotate(-60.515 176.998 135.953)"
            cx={177}
            cy={136}
            fill="#010205"
            r={40.1}
          />
          <path
            d="M195.5 105.4c-8.1 11.5-3.7 27.4 9.2 33.1 9.5 4.2 20.9 1 26.9-7.5 8.1-11.5 3.7-27.4-9.2-33.1-9.5-4.1-20.9-1-26.9 7.5z"
            fill="#f4ece8"
          />
        </g>
        <g>
          <circle
            transform="rotate(-35.8 424.641 163.544)"
            cx={424.6}
            cy={163.5}
            fill="#f4ece8"
            r={67.5}
          />
          <circle
            transform="rotate(-6.332 424.717 163.641)"
            cx={424.6}
            cy={163.5}
            fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
            r={46.2}
          />
          <circle
            transform="rotate(-6.332 424.717 163.641)"
            cx={424.6}
            cy={163.5}
            fill="#010205"
            r={31.9}
          />
          <path
            d="M422.4 135.3c.1 11.2 10.4 19.4 21.3 17 8.1-1.7 14-9.1 13.9-17.3-.1-11.2-10.4-19.4-21.3-17-8.1 1.7-14 9-13.9 17.3z"
            fill="#f4ece8"
          />
        </g>
        <g>
          <circle cx={514} cy={227} r={30} fill="#f4ece8" />
          <circle
            transform="rotate(-60.515 514.009 226.944)"
            cx={514}
            cy={227}
            fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
            r={20.5}
          />
          <circle
            transform="rotate(-60.515 514.009 226.944)"
            cx={514}
            cy={227}
            fill="#010205"
            r={14.2}
          />
          <path
            d="M520.5 216.2c-2.9 4.1-1.3 9.7 3.2 11.7 3.4 1.5 7.4.4 9.5-2.6 2.9-4.1 1.3-9.7-3.2-11.7-3.3-1.5-7.4-.4-9.5 2.6z"
            fill="#f4ece8"
          />
        </g>
        <g>
          <circle cx={378} cy={62} r={30} fill="#f4ece8" />
          <circle
            transform="rotate(-60.515 378 61.945)"
            cx={378}
            cy={62}
            fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
            r={20.5}
          />
          <circle
            transform="rotate(-60.515 378 61.945)"
            cx={378}
            cy={62}
            fill="#010205"
            r={14.2}
          />
          <path
            d="M384.5 51.2c-2.9 4.1-1.3 9.7 3.2 11.7 3.4 1.5 7.4.4 9.5-2.6 2.9-4.1 1.3-9.7-3.2-11.7-3.3-1.5-7.4-.4-9.5 2.6z"
            fill="#f4ece8"
          />
        </g>
        <g>
          <circle cx={304} cy={218} r={52} fill="#f4ece8" />
          <circle
            transform="rotate(-60.515 304.003 217.95)"
            cx={304}
            cy={218}
            fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
            r={35.6}
          />
          <circle
            transform="rotate(-60.515 304.003 217.95)"
            cx={304}
            cy={218}
            fill="#010205"
            r={24.5}
          />
          <path
            d="M315.3 199.3c-5 7-2.3 16.8 5.6 20.2 5.8 2.6 12.8.6 16.5-4.6 5-7 2.3-16.8-5.6-20.2-5.8-2.6-12.8-.6-16.5 4.6z"
            fill="#f4ece8"
          />
        </g>
        <g>
          <circle cx={307} cy={109} r={37} fill="#f4ece8" />
          <circle
            transform="rotate(-60.515 307 108.948)"
            cx={307}
            cy={109}
            fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
            r={25.3}
          />
          <circle
            transform="rotate(-60.515 307 108.948)"
            cx={307}
            cy={109}
            fill="#010205"
            r={17.5}
          />
          <path
            d="M315.1 95.7c-3.5 5-1.6 11.9 4 14.4 4.1 1.8 9.1.5 11.7-3.3 3.5-5 1.6-11.9-4-14.4-4.2-1.8-9.1-.4-11.7 3.3z"
            fill="#f4ece8"
          />
        </g>
      </g>
    </>
  );
}

const MemoEyes7 = React.memo(Eyes7);
export default MemoEyes7;
