import * as React from 'react';

function Mouth6(props) {
  return (
    <>
      <path
        d="M202.8 366.5H396s-16.5 180.7-96.6 180.7-96.6-180.7-96.6-180.7z"
        fill="#010205"
      />
      <defs>
        <path
          id="prefix__a6"
          d="M202.8 366.5H396s-16.5 180.7-96.6 180.7-96.6-180.7-96.6-180.7z"
        />
      </defs>
      <clipPath id="prefix__b6">
        <use xlinkHref="#prefix__a6" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#prefix__b6)"
        fill="#fff"
        d="M149.9 309h95.3l-47.6 113.9z"
      />
      <path
        clipPath="url(#prefix__b6)"
        fill="#fff"
        d="M213.4 309h95.3L261 422.9z"
      />
      <path
        clipPath="url(#prefix__b6)"
        fill="#fff"
        d="M276.9 309h95.3l-47.7 113.9z"
      />
      <path
        clipPath="url(#prefix__b6)"
        fill="#fff"
        d="M340.4 309h95.2L388 422.9z"
      />
      <path
        clipPath="url(#prefix__b6)"
        fill="#fff"
        d="M403.9 309h95.2l-47.6 113.9zM474.5 568.5h-95.2l47.6-113.9z"
      />
      <path
        clipPath="url(#prefix__b6)"
        fill="#fff"
        d="M411 568.5h-95.2l47.6-113.9z"
      />
      <path
        clipPath="url(#prefix__b6)"
        fill="#fff"
        d="M347.5 568.5h-95.2l47.6-113.9z"
      />
      <path
        clipPath="url(#prefix__b6)"
        fill="#fff"
        d="M284 568.5h-95.2l47.6-113.9z"
      />
      <path
        clipPath="url(#prefix__b6)"
        fill="#fff"
        d="M220.5 568.5h-95.2l47.6-113.9z"
      />
    </>
  );
}

const MemoMouth6 = React.memo(Mouth6);
export default MemoMouth6;
