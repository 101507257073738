import * as React from 'react';

function Mouth8(props) {
  return (
    <>
      <path
        d="M166.1 338.4s-37-18.1-62.3 17.8c-21.3 30.2-10.3 148.9 178.7 150.2 201 1.4 223.6-131.4 223.6-131.4s7.9-50.3-46.2-39.6c-54 10.7-77.6 68.6-293.8 3z"
        fill="#2d201e"
      />
      <defs>
        <path
          id="prefix__a8"
          d="M166.1 338.4s-37-18.1-62.3 17.8c-21.3 30.2-10.3 148.9 178.7 150.2 201 1.4 223.6-131.4 223.6-131.4s7.9-50.3-46.2-39.6c-54 10.7-77.6 68.6-293.8 3z"
        />
      </defs>
      <clipPath id="prefix__b8">
        <use xlinkHref="#prefix__a8" overflow="visible" />
      </clipPath>
      <g clipPath="url(#prefix__b8)" fill="#f7f5f6">
        <path d="M161.9 328.9s-28.3 65 27.3 74.2c62.7 10.4 67.2-51.6 67.2-51.6l-94.5-22.6z" />
        <path d="M251.4 344s-1.7 35.5 1.9 40.1c3.6 4.6 5 32.6 51.9 28.9s46.4-15.8 45.4-57-99.2-12-99.2-12zM350.2 546.9s6-70.5 6.1-79.6c.1-15-1.4-51.7-48.8-50.3-63.9 1.9-66.8 6.7-81.9 72.1-15.1 65.3 124.6 57.8 124.6 57.8z" />
        <path d="M418.4 333.5s13.7 29.5 13.1 40.7c-.6 11.2.2 26.2-29.6 29.8-29.8 3.7-40.1 7.6-46.2-20.2-6.1-27.8-8.7-32.5-8.7-32.5l71.4-17.8zM199 514.7s16.3-2.6 25-44.7-9.9-61.9-38.4-64.5c-28.5-2.5-45.5-1.4-58.2 26.3-12.7 27.8-13.1 40.9-13.1 40.9l84.7 42zM461.8 489.1s-15.2-91-62.8-80.1-42.5 117.1-42.5 117.1l105.3-37z" />
      </g>
    </>
  );
}

const MemoMouth8 = React.memo(Mouth8);
export default MemoMouth8;
