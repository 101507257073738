import * as React from 'react';

function Mouth7(props) {
  return (
    <>
      <path d="M245.8 353.8c-5.2-.2-19.8 1.7-49.1-6s-90.4-36.1-109.3 37C70.1 452 141.7 503.6 195 474.3s65.4-46.5 93.8-37.9 111 49.1 161.8 32.7c50.8-16.4 63.4-51.2 59.4-86.9-6.3-56.4-45.4-84.1-99.8-85.2-54.2-1.1-86.1 17.2-106.7 31s-32.7 26.7-57.7 25.8z" />
      <defs>
        <path
          id="prefix__a7"
          d="M245.8 353.8c-5.2-.2-19.8 1.7-49.1-6s-90.4-36.1-109.3 37C70.1 452 141.7 503.6 195 474.3s65.4-46.5 93.8-37.9 111 49.1 161.8 32.7c50.8-16.4 63.4-51.2 59.4-86.9-6.3-56.4-45.4-84.1-99.8-85.2-54.2-1.1-86.1 17.2-106.7 31s-32.7 26.7-57.7 25.8z"
        />
      </defs>
      <clipPath id="prefix__b7">
        <use xlinkHref="#prefix__a7" overflow="visible" />
      </clipPath>
      <g clipPath="url(#prefix__b7)" fill="#fff">
        <path d="M105.1 330.7c0 2.6-2.6 59.4 23.2 59.4s32.7-18.9 33.6-29.3c.9-10.3 0-30.1 0-30.1h-56.8zM377.9 295.9c0 2.6-7.3 59.4 42.6 59.4 34.4 0 33.1-59.4 33.1-59.4h-75.7zM354.2 301s17.2 31.8 11.2 38.7c-6 6.9-23.2 20.7-37.9 6.9S306 319.1 306 319.1l48.2-18.1z" />
      </g>
    </>
  );
}

const MemoMouth7 = React.memo(Mouth7);
export default MemoMouth7;
