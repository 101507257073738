import * as React from 'react';

function Skin1(props) {
  const skin = props.skinsettings;
  return (
    <>
      <g opacity={skin.opacity} fill={skin.color}>
        <path d="M226.3 27.1c11.7-10.9 27.4-12.6 44.1 5.4s20.7 41.4 8.9 52.2c-11.7 10.9-45.9 15-62.5-3s-2.3-43.8 9.5-54.6zM445.7 590.6c-12.6-5.7-19.3-17.6-10.6-37 8.7-19.3 26-30.4 38.6-24.7 12.6 5.7 27.4 31.5 18.7 50.9-8.7 19.3-34.1 16.5-46.7 10.8zM582.7 571.1c-6.4 12.3-27.4 14-40.7 7.8-13-6.1-13-27.7-8.7-34.7 7.3-11.7 17.1-21 36.4-12.1 11.2 5.2 19.4 26.7 13 39zM536.7 156.9c-11.2-1.9-19-10.1-16-27.3 3-17.2 14.5-29.6 25.7-27.6 11.2 1.9 28.3 19.5 25.3 36.7s-23.8 20.2-35 18.2zM80.4 149.3c-8.2-8.6-9.8-20.3 3.4-32.8s25-9 33.3-.4 17.1 27.5 4 40c-13.1 12.6-32.4 1.9-40.7-6.8zM19.5 382.9c-9.5-25.4-3.3-73.8 13.7-80.8 31-12.8 43.3 35 45.3 63.5 1.9 27.1 17.3 64.7 0 71.2s-49.5-28.4-59-53.9zM532.7 471.1c3-9.9 11.2-16 26.2-11.4s17.4 15.1 14.4 25c-3 9.9-13.5 24.6-28.5 20-15-4.6-15.1-23.7-12.1-33.6zM13.5 240.7c0-9.6 5.2-17.3 19-17.3 13.8-.1 18.5 8.6 18.5 18.2s-4.9 25.6-18.7 25.6c-13.7.1-18.8-16.9-18.8-26.5zM165.4 85.3c6.9-6.4 16.1-7.5 25.6 2.8s2.4 18.4-4.5 24.8c-6.9 6.4-12 7.7-21.5-2.6s-6.5-18.6.4-25zM3 65.1l-3-.6v153.3c18.4-10 34.5-32.7 41.4-61.5C51.8 113 31.2 71.8 3 65.1zM46.5 0C36.2 14.2 30 29.6 30 41.5c0 29 36.1 52.5 80.6 52.5s61.6-23 61.6-52c0-12-2.8-27.7-9.3-42H46.5zM401.2 0c-14.7 13.3-14.1 27.8-14.1 39 0 27.2 51.5 49.3 115 49.3S590 66.7 590 39.5c0-11.2-4-26-13.2-39.5H401.2z" />
        <ellipse
          transform="rotate(-56.544 344.333 41.818)"
          cx={344.3}
          cy={41.8}
          rx={18.3}
          ry={34.3}
        />
        <ellipse
          transform="rotate(-45.001 519.417 198.876)"
          cx={519.4}
          cy={198.9}
          rx={12.9}
          ry={16.3}
        />
        <ellipse
          transform="rotate(-52.836 564.867 276.471)"
          cx={564.9}
          cy={276.5}
          rx={15}
          ry={5.8}
        />
        <path d="M600 295.9c-2.6-1.8-5.5-2.9-8.5-3.3-18.6-2.1-37 25.3-41.2 61.3-4.1 36 7.6 66.9 26.2 69.1 8.3 1 16.6-4 23.6-13V295.9z" />
        <ellipse cx={500.8} cy={503.5} rx={10.8} ry={14.7} />
        <ellipse cx={107.3} cy={466.8} rx={12.7} ry={17.3} />
        <ellipse
          transform="rotate(-48.731 141.153 539.186)"
          cx={141.1}
          cy={539.2}
          rx={38.9}
          ry={52.6}
        />
        <path d="M74.5 600c3.6-19.6 1.6-42.2-6.9-64.4-14.9-38.6-40-52.4-67.6-50.4V600h74.5z" />
      </g>
    </>
  );
}

const MemoSkin1 = React.memo(Skin1);
export default MemoSkin1;
