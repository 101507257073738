import * as React from 'react';

function Skin6(props) {
  const skin = props.skinsettings;
  return (
    <>
      <g opacity={skin.opacity} fill={skin.color}>
        <path d="M120.8 156.7c-11.4-8.8-7.2-17.1 6.2-19.5 9.1-1.6 11.4.1 19.3-4.5 5.6-3.3 4.9-6.5 11.6-7.4 7.1-1 13.8 4 20.6 5 8.6 1.3 14.3-.4 22.5-2.4 9.2-2.2 25.9-2 17.7 12.9-3.3 6.1-10.9 4.2-15.4 7.2-1.8 1.3-4 1.9-6 3.6-1.6 1.4 1.7 6.4-.9 8.2-4.4 3.1-15.1-3.6-17.9 4-3.2 9 7 3.1 9.4 5.6 12.1 13.4-6.8 9.3-14.3 6.9-19.3-6.1-13.4.7-21.3 12.6-2.6 4-6.7 6.8-9.4 10.6-3.3 4.7-3.9 12.6-6.9 16.3-5.5 6.9-9.6 3.7-12.7-6.6-4.6-15.2 9.4-19.2 13-32.3 3.7-13.4-6.4-13.2-15.5-20.2zM443 .1c-1.5 9.4-2.3 20.8-1.9 34.8l3.7 7.5c9.5-4.1 12.7 22.3 19 45.4 5.9 21.7 17.4 27.5 24.9 24.3 6.6-2.8 13.9-4.1 20.5-13.1 6.3-8.7 11.3-28.9 17.9-31.7 8.4-3.6 12.6-5.2 19.5-27.4 4.1-13.1 8.9-20.7 11.3-39.8H443zM.5 163.6v39c9.2-.3 17.8-2.8 19.3-12.6 1.4-9.3-10-18.5-19.3-26.4zM108.6 231.9c-16.3-4.7-31.5 3.6-47 4.8-16.6 1.3-31.7-4-45.9-11.6C9.2 221.6 4.3 219 0 217v78c13.8-9.9 24.3-24.6 39.9-27.9 27.6-5.9 45.5 13.4 69.7 16.2 45.3 5.1 30.4-42.3-1-51.4zM599 128.8c-3.1-1.4-5.3-1.8-10.6-1.8-6.2 0-5.4.9-9.8 2.9-8.1 3.5-12.6 3.6-20.8 3.2-10.2-.5-25.3 2.9-31.7 11.8l2.4 5.4c12 10.6 22.5 7.4 37 3.6 10-2.6 19.3 3 28.7.6 1.9-.5 3.5-1.4 5-2.4v-23.3zM0 321v157.3c8-.8 16.3-2 25.2-4 62.3-13.7-5.1-28.8-12.5-46-8.3-19.2 28.4-24.5 57.8-35.4 51.8-19.2 21.2-33-16.6-50.6C37.5 334.6 18.6 327.8 0 321zM599 420.6c-.5.4-1.1.8-1.8.9-5.9.5-15.4-3.2-18.6 5.4-2.7 7.4 8.4 13.8 14.3 9.9 2-1.3 2.7-3.7 4.9-4.8.4-.2.8-.4 1.2-.5v-10.9zM599 447.6c-5.3-.8-10.2.9-12.6 6.9-3 7.4 1 9.3 5.5 12.5.8.6 4.4.2 7.1.5v-19.9zM599 191.9c-22 4.6-42.3 15-64.8 28.4l5.5 5.5c7.5-.6 14.5 1.7 21.7 3.2 1.4 6.5.3 12.9-2.8 18.4-25.1 1.5-72.3-10.7-76.8 22.6-4.2 31.3 14 11.7 29.1 22.6 13.9 10 6.6 20.1 12.8 34.8 14.4.8 39.2-4.4 49.2 6 22.9 23.6-11.1 30.9-19.2 44.2-14.3 23.3-1.2 30.9 19.2 30.3 16.1-.5 21.8-5.4 26.2-12.9V191.9zM594 43.6c-.7 0-1.3-.1-2-.1-24.1-.9-38.2 20.5-26.2 42.8 4.6 8.6 15.4 16.6 28.2 22.7V43.6z" />
        <path d="M83.1 88.1C51.4 63.2 45.2 30.6.5 27.5v110c34 16.4 76.4 25.2 99.9 17.9 63.8-20 5.6-49.3-17.3-67.3zM81.1 508.1c6.5-2.3 9.7-9.1 15.2-12.3 9.2-5.3 21.6-2.9 29.7-10.2 14-12.6 6.4-50.5-15.1-38.1-9.5 5.5-3.3 21.2-10.1 28.2-8.1 8.3-20.2.6-30.2 6-19.8 10.6-12.9 34.6 10.5 26.4zM413.3 66.9c-3.1-17.3-17.4-45.4-13-66.8h-23c7.8 17.1 7.5 49.3 15 74.9 8.2 28.1 28.7 35 21-8.1zM286.5.1h-236C61.7 32.8 71.2 39.6 93.7 42c13.3 1.4 12.6-2.4 22.3-15.6 6.7-9.2 15.1-20.4 23.2-21.8 17.5-3.1 21.4 44.5 36.4 41C193.5 41.3 198.8-12 221.8 4c6.4 4.5 11.2 10.6 10.9 25.1-.3 16.9-9.2 17.4-11.3 29.5-4.5 26.1 20.9 49.7 32.7 55 12.3 5.7 51.5 3 56.6-30.4 3.4-22.7-18.4-57-22.8-75.7-.6-2.6-1-5-1.4-7.4zM66.4 599h301c-6.1-17.3 6.9-27.8 27-29.1 16-1.1 21.3 8.8 34.9 16.8 12.6 7.4 22.9 4.5 37.3 5.5 7.7.6 12.9 3.1 16.7 6.9h29.4c.6-1.4 1.5-2.8 2.9-4.1 9.9-10 33.6 2.7 38-19.4 5.2-26.3-27.2-23-34.8-39.4-10.2-21.8 22-7 32-12.2 29.1-15.3-36.7-38.7-54.2-33.6-17 4.9-30 19-48.1 22.1-24.5 4.2-54.2 3.9-72.9-11.4-18.3-14.9-35.9-34.4-60.5-28.3-20.8 5.2-23.9 4.8-42.1 1.2-7.3-1.5-19.3-3.7-26.6-2.3-28.1 5.3-33.5 46.9-59.3 53.5-16.6 4.3-32.3-15.1-48.8-9.5-23.5 8.1-18.7 54.7-45.6 51.8-17.5-1.9-25.1-39.3-45.1-25.1-17.9 12.7 6.1 37.3 13.1 48.2 1.9 3 3.8 5.8 5.7 8.4z" />
      </g>
    </>
  );
}

const MemoSkin6 = React.memo(Skin6);
export default MemoSkin6;
