import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../Modal';

import TreasureChest from './TreasureChest';
import AchievePop from './AchievePop';

import styles from './GemsRewards.module.scss';

export const RewardNotification = ({ reward, clearReward, withNav }) => {
  const [achieveModal, setAchieveModal] = useState(false);
  const { t } = useTranslation(['shared']);

  return (
    <Fragment>
      <AchievePop openModal={() => setAchieveModal(true)} withNav={withNav} />
      {achieveModal && (
        <Modal
          closeModal={() => {
            clearReward();
            setAchieveModal(false);
          }}
        >
          <div className={styles.achieveModalContent}>
            <h2>{t('how_many_gems_earned')}</h2>
            <TreasureChest gems={reward.new_gems} message={reward.message} />
          </div>
        </Modal>
      )}
    </Fragment>
  );
};
