import * as React from 'react';

function Eyes3(props) {
  const color = props.eyecolor;
  return (
    <>
      <circle
        transform="rotate(-16.266 207.712 171.005)"
        cx={207.7}
        cy={171}
        fill="#f4ece8"
        r={158.8}
      />
      <circle
        transform="rotate(-16.266 207.751 170.933)"
        cx={207.8}
        cy={170.9}
        fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
        r={131.4}
      />
      <circle
        transform="rotate(-16.266 207.567 170.83)"
        cx={207.6}
        cy={170.8}
        fill="#010205"
        r={90.7}
      />
      <circle
        transform="rotate(-16.266 264.035 87.258)"
        cx={264}
        cy={87.3}
        fill="#f4ece8"
        r={50}
      />
      <circle
        transform="rotate(-16.266 443.324 207.474)"
        cx={443.3}
        cy={207.5}
        fill="#f4ece8"
        r={57.8}
      />
      <circle
        transform="rotate(-16.266 443.338 207.448)"
        cx={443.3}
        cy={207.4}
        fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
        r={47.8}
      />
      <circle
        transform="rotate(-16.266 443.271 207.41)"
        cx={443.3}
        cy={207.4}
        fill="#010205"
        r={33}
      />
      <circle
        transform="rotate(-16.266 463.833 176.979)"
        cx={463.8}
        cy={177}
        fill="#f4ece8"
        r={18.2}
      />
    </>
  );
}

const MemoEyes3 = React.memo(Eyes3);
export default MemoEyes3;
