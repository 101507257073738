import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'react-feather';
import styles from './Dropdown.module.scss';
import cx from 'classnames';

export const Dropdown = ({
  classNames,
  children,
  titleLeft,
  titleRight,
  leftAligned,
  dropdownOpts,
}) => {
  const [visible, setVisible] = useState();

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, []);

  return (
    <div ref={ref} className={cx(classNames, styles.dropdown)}>
      <button
        className={cx(styles.dropdownBtn, {
          [styles.dropdownBtnIsActive]: visible,
        })}
        onClick={() => setVisible(!visible)}
      >
        {titleLeft && (
          <span className={styles.dropdownBtnTitleLeft}>{titleLeft}</span>
        )}
        <Menu />
        {titleRight && (
          <span className={styles.dropdownBtnTitleRight}>{titleRight}</span>
        )}
      </button>
      {visible && (
        <ul
          className={cx(styles.dropdownMenu, {
            [styles.dropdownMenuLeft]: leftAligned,
          })}
        >
          {dropdownOpts &&
            dropdownOpts.length &&
            dropdownOpts.map((option, i) => {
              return (
                <li key={i}>
                  <button disabled={option.disabled} onClick={option.action}>
                    {option.text}
                  </button>
                </li>
              );
            })}

          {children}
        </ul>
      )}
    </div>
  );
};
