import React from 'react';
import { useTranslation } from 'react-i18next';

import book from './images/book.png';
import styles from './ErrorPage.module.scss';

export const ErrorPage = ({ clearError }) => {
  const { t } = useTranslation(['shared']);
  return (
    <div className="auth-page__container">
      <div className={styles.errorPage}>
        <h1 className={styles.errorPageTitle}>{t('oops')}</h1>
        <img className={styles.errorPageImage} alt="" src={book} />
        <p className={styles.errorPageText}>{t('something_has_gone_wrong')}</p>
        <a className={styles.errorPageButton} href="/">
          {t('back_to_dashboard')}
        </a>
        {/*clearError ? (
          <button className={styles.errorPageButton} onClick={() => clearError}>
            {t('back_to_dashboard')}
          </button>
        ) : (
          <a className={styles.errorPageButton} href="/">
            {t('back_to_dashboard')}
          </a>
        )*/}
      </div>
    </div>
  );
};
