import * as React from 'react';

function Mouth5(props) {
  return (
    <>
      <path
        d="M208.5 333.5c62.1-12.7 138.9 53.7 241.1 0 0 0 29.5 154.7-112.6 130.5-142.1-24.2-154.7-12.8-163.2 2.6-8.5 15.3-68.5-112 34.7-133.1z"
        fill="#010205"
      />
      <defs>
        <path
          id="prefix__a5"
          d="M208.5 333.5c62.1-12.7 138.9 53.7 241.1 0 0 0 29.5 154.7-112.6 130.5-142.1-24.2-154.7-12.8-163.2 2.6-8.5 15.3-68.5-112 34.7-133.1z"
        />
      </defs>
      <clipPath id="prefix__b5">
        <use xlinkHref="#prefix__a5" overflow="visible" />
      </clipPath>
      <path
        d="M184.2 228h83.1c32 0 53.7 32.6 41.4 62.2l-41.5 99.3c-15.4 36.7-67.4 36.7-82.8 0l-41.5-99.3c-12.5-29.6 9.2-62.2 41.3-62.2z"
        clipPath="url(#prefix__b5)"
        fill="#fff"
      />
      <path
        d="M329.4 228h83.1c32 0 53.7 32.6 41.4 62.2l-41.5 99.3c-15.4 36.7-67.4 36.7-82.8 0L288 290.1c-12.3-29.5 9.4-62.1 41.4-62.1z"
        clipPath="url(#prefix__b5)"
        fill="#fff"
      />
    </>
  );
}

const MemoMouth5 = React.memo(Mouth5);
export default MemoMouth5;
