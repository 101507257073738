import React from 'react';
import { Location } from '@reach/router';

export const Ssl = () => {
  return (
    <Location>
      {() => {
        if (
          window.location.protocol !== 'https:' &&
          window.location.hostname !== 'localhost'
        ) {
          window.location.protocol = 'https:';
        }
        return null;
      }}
    </Location>
  );
};
