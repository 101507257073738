import * as React from 'react';

function Eyes1(props) {
  const color = props.eyecolor;
  return (
    <>
      <g>
        <circle cx={166.8} cy={199.4} r={81.7} fill="#f4ece8" />
        <circle
          cx={166.8}
          cy={199.4}
          r={67.6}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
        />
        <circle cx={166.8} cy={199.3} r={46.6} fill="#010205" />
        <circle cx={206.7} cy={166.2} r={25.7} fill="#f4ece8" />
        <circle cx={383} cy={163} r={118.2} fill="#f4ece8" />
        <circle
          cx={383.1}
          cy={162.9}
          r={97.8}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
        />
        <circle cx={383} cy={162.8} r={67.5} fill="#010205" />
        <circle cx={440.7} cy={114.9} r={37.2} fill="#f4ece8" />
      </g>
    </>
  );
}

const MemoEyes1 = React.memo(Eyes1);
export default MemoEyes1;
