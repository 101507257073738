import * as React from 'react';

function Eyes4(props) {
  const color = props.eyecolor;
  return (
    <>
      <g>
        <circle cx={432} cy={183.1} r={79.3} fill="#f4ece8" />
        <circle
          cx={432}
          cy={183.1}
          r={65.6}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
        />
        <circle cx={432} cy={183} r={45.3} fill="#010205" />
        <circle cx={470.7} cy={150.9} r={25} fill="#f4ece8" />
        <circle cx={140} cy={229.4} r={44.6} fill="#f4ece8" />
        <circle
          cx={140.1}
          cy={229.4}
          r={36.9}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
        />
        <circle cx={140} cy={229.3} r={25.4} fill="#010205" />
        <circle cx={161.8} cy={211.2} r={14} fill="#f4ece8" />
        <circle
          transform="rotate(-80.781 285.108 253.402)"
          cx={285.1}
          cy={253.4}
          fill="#f4ece8"
          r={28.7}
        />
        <circle
          transform="rotate(-80.781 285.117 253.414)"
          cx={285.1}
          cy={253.4}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
          r={23.7}
        />
        <circle
          transform="rotate(-80.781 285.147 253.392)"
          cx={285.1}
          cy={253.4}
          fill="#010205"
          r={16.4}
        />
        <circle
          transform="rotate(-80.781 294.375 269.074)"
          cx={294.4}
          cy={269.1}
          fill="#f4ece8"
          r={9}
        />
        <circle
          transform="rotate(-73.14 254.969 107.089)"
          cx={255}
          cy={107.1}
          fill="#f4ece8"
          r={80.6}
        />
        <circle
          transform="rotate(-73.14 254.95 107.052)"
          cx={254.9}
          cy={107}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
          r={66.7}
        />
        <circle
          transform="rotate(-73.14 254.854 107.101)"
          cx={254.8}
          cy={107.1}
          fill="#010205"
          r={46}
        />
        <circle
          transform="rotate(-73.14 234.985 59.906)"
          cx={235}
          cy={59.9}
          fill="#f4ece8"
          r={25.4}
        />
      </g>
    </>
  );
}

const MemoEyes4 = React.memo(Eyes4);
export default MemoEyes4;
