import * as React from 'react';

function Eyes5(props) {
  const color = props.eyecolor;
  return (
    <>
      <g>
        <circle
          transform="rotate(-45.001 298.912 86.299)"
          cx={298.9}
          cy={86.3}
          fill="#f4ece8"
          r={61.1}
        />
        <circle
          transform="rotate(-45.001 298.912 86.267)"
          cx={298.9}
          cy={86.3}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
          r={50.5}
        />
        <circle
          transform="rotate(-45.001 298.83 86.266)"
          cx={298.8}
          cy={86.3}
          fill="#010205"
          r={34.9}
        />
        <circle
          transform="rotate(-45.001 302.423 47.64)"
          cx={302.4}
          cy={47.6}
          fill="#f4ece8"
          r={19.2}
        />
        <circle
          transform="rotate(-45.001 463.538 188.763)"
          cx={463.5}
          cy={188.8}
          fill="#f4ece8"
          r={88.4}
        />
        <circle
          transform="rotate(-45.001 463.538 188.717)"
          cx={463.5}
          cy={188.7}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
          r={73.1}
        />
        <circle
          transform="rotate(-45.001 463.421 188.716)"
          cx={463.4}
          cy={188.7}
          fill="#010205"
          r={50.5}
        />
        <circle
          transform="rotate(-45.001 468.617 132.84)"
          cx={468.6}
          cy={132.8}
          fill="#f4ece8"
          r={27.8}
        />
        <circle
          transform="rotate(-45.001 138.136 188.759)"
          cx={138.1}
          cy={188.8}
          fill="#f4ece8"
          r={88.4}
        />
        <circle
          transform="rotate(-45.001 138.136 188.713)"
          cx={138.1}
          cy={188.7}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
          r={73.1}
        />
        <circle
          transform="rotate(-45.001 138.018 188.712)"
          cx={138}
          cy={188.7}
          fill="#010205"
          r={50.5}
        />
        <circle
          transform="rotate(-45.001 143.215 132.837)"
          cx={143.2}
          cy={132.8}
          fill="#f4ece8"
          r={27.8}
        />
      </g>
    </>
  );
}

const MemoEyes5 = React.memo(Eyes5);
export default MemoEyes5;
