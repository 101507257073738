import * as React from 'react';

function Eyes2(props) {
  const color = props.eyecolor;
  return (
    <>
      <g>
        <circle
          transform="translate(-.334 .23)"
          cx={136.8}
          cy={198.4}
          fill="#f4ece8"
          r={81.7}
        />
        <circle
          transform="translate(-.334 .23)"
          cx={136.8}
          cy={198.3}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
          r={67.6}
        />
        <circle
          transform="translate(-.334 .23)"
          cx={136.7}
          cy={198.4}
          fill="#010205"
          r={46.6}
        />
        <circle
          transform="translate(-.267 .175)"
          cx={103.5}
          cy={158.5}
          fill="#f4ece8"
          r={25.7}
        />
        <circle cx={441.5} cy={198.4} r={81.7} fill="#f4ece8" />
        <circle
          cx={441.5}
          cy={198.3}
          r={67.6}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
        />
        <ellipse cx={441.5} cy={198.3} rx={46.6} ry={46.7} fill="#010205" />
        <circle cx={481.4} cy={156.9} r={25.7} fill="#f4ece8" />
      </g>
    </>
  );
}

const MemoEyes2 = React.memo(Eyes2);
export default MemoEyes2;
