import React from 'react';
import ActionCable from 'actioncable';
import {
  ActionCableConsumer,
  ActionCableProvider,
} from '@thrash-industries/react-actioncable-provider';

export const AuthActionCable = ({ id, onLogout, onReward, onForcedLogout }) => {
  const hostname = window && window.location && window.location.hostname;

  const cableEndpoint = () => {
    if (
      hostname !== 'localhost' &&
      !hostname.includes('staging') &&
      hostname.includes('boom')
    ) {
      return 'wss://cable.boomhub.app/cable';
    }
    if (hostname !== 'localhost' && !hostname.includes('staging')) {
      return 'wss://cable.goapps.app/cable';
    }
    if (hostname === 'localhost') {
      return 'wss://goportal-cable.herokuapp.com/cable';
    }
    return 'wss://goapps-staging-cable.herokuapp.com/cable';
  };

  const cable = ActionCable.createConsumer(cableEndpoint());

  // const cable = ActionCable.createConsumer(
  //   hostname !== 'localhost' && !hostname.includes('staging')
  //     ? 'wss://api.goapps.app/cable'
  //     : hostname === 'localhost'
  //     ? 'wss://goportal-cable.herokuapp.com/cable'
  //     : 'wss://goapps-staging-cable.herokuapp.com/cable',
  // );

  return (
    <ActionCableProvider cable={cable}>
      <ActionCableConsumer
        channel={{
          channel: 'NotificationsChannel',
          id,
        }}
        onReceived={(message) => {
          if (message.logged_out) {
            onLogout();
          }
          if (message.reward && onReward) {
            onReward(message.reward);
          }
          if (message.forced_logged_out && onForcedLogout) {
            onForcedLogout();
          }
        }}
      ></ActionCableConsumer>
    </ActionCableProvider>
  );
};
