import * as React from 'react';

function Mouth3(props) {
  return (
    <>
      <path
        d="M128.8 404.1s176.1 131.2 383.7-46.3c-.1 0-241.9 418.5-383.7 46.3z"
        fill="#010205"
      />
      <defs>
        <path
          id="prefix__a3"
          d="M128.8 404.1s176.1 131.2 383.7-46.3c-.1 0-241.9 418.5-383.7 46.3z"
        />
      </defs>
      <clipPath id="prefix__b3">
        <use xlinkHref="#prefix__a3" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#prefix__b3)"
        fill="#f4ece8"
        d="M182.2 391.1l37.7 111.7 52.6-121.5zM284 433.8v95.3l48.1-115zM339.8 414.1l36.2 77.2 36.1-110z"
      />
    </>
  );
}

const MemoMouth3 = React.memo(Mouth3);
export default MemoMouth3;
