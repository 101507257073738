import * as React from 'react';

function Eyes6(props) {
  const color = props.eyecolor;
  return (
    <>
      <g>
        <circle cx={299.5} cy={179.8} r={144.5} fill="#f4ece8" />
        <circle
          cx={299.5}
          cy={179.8}
          r={119.5}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
        />
        <circle cx={299.4} cy={179.7} r={82.5} fill="#010205" />
        <circle cx={370} cy={121.1} r={45.5} fill="#f4ece8" />
      </g>
    </>
  );
}

const MemoEyes6 = React.memo(Eyes6);
export default MemoEyes6;
