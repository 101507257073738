import * as React from 'react';

function Eyes8(props) {
  const color = props.eyecolor;
  return (
    <>
      <g>
        <path
          d="M353.6 272c51.5-25.5 92.2-25.1 120-18.3 26.5-22.5 43.4-55.7 43.4-89.4 0-59.6-52.8-109.9-117.8-109.9s-117.8 50.3-117.8 109.9c-.1 44.7 29.7 88.6 72.2 107.7z"
          fill="#f4ece8"
        />
        <circle
          transform="rotate(-16.266 399.521 158.678)"
          cx={399.5}
          cy={158.7}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
          r={78.4}
        />
        <circle
          transform="rotate(-16.423 399.439 158.593)"
          cx={399.4}
          cy={158.6}
          fill="#010205"
          r={54.1}
        />
        <circle
          transform="rotate(-16.266 433.122 108.724)"
          cx={433.1}
          cy={108.7}
          fill="#f4ece8"
          r={29.9}
        />
        <g>
          <circle cx={185} cy={180} r={82} fill="#f4ece8" />
          <circle
            transform="rotate(-16.266 184.937 180.094)"
            cx={184.9}
            cy={180.1}
            fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
            r={53}
          />
          <circle
            transform="rotate(-16.423 184.883 180.045)"
            cx={184.9}
            cy={180.1}
            fill="#010205"
            r={36.6}
          />
          <circle
            transform="rotate(-16.266 207.65 146.327)"
            cx={207.6}
            cy={146.3}
            fill="#f4ece8"
            r={20.2}
          />
        </g>
      </g>
    </>
  );
}

const MemoEyes8 = React.memo(Eyes8);
export default MemoEyes8;
