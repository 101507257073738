import * as React from 'react';

function Mouth1(props) {
  return (
    <>
      <path
        d="M86.9 297.8s160.2 183.6 405.6 1.8c0 0-218.5 435.4-405.6-1.8z"
        fill="#010205"
      />
      <defs>
        <path
          id="prefix__a1"
          d="M86.9 297.8s160.2 183.6 405.6 1.8c0 0-228.5 552.4-405.6-1.8z"
        />
      </defs>
      <clipPath id="prefix__b1">
        <use xlinkHref="#prefix__a1" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#prefix__b1)"
        fill="#fff"
        d="M292.3 308.9L270 464l116.2 7-23.9-162.1zM287.2 324.6L248.8 474l-83.9-14.4 57.2-143.2z"
      />
    </>
  );
}

const MemoMouth1 = React.memo(Mouth1);
export default MemoMouth1;
