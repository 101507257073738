import React from 'react';
import cx from 'classnames';

import styles from './Button.module.scss';

export const Button = ({ children, className, disabled, fullWidth, large, primary, outline, wide, onClick, type }) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            type={type}
            className={
                className,
                cx(className, styles.button, {[styles.buttonFullWidth]: fullWidth}, {[styles.buttonLarge]: large}, {[styles.buttonPrimary]: primary}, {[styles.buttonOutline]: outline}, {[styles.buttonWide]: wide})
            }
        >
            {children}
        </button>
    )
}
