import React from 'react';
import { X } from 'react-feather';

import styles from './Modal.module.scss';

export const Modal = ({ children, closeModal }) => {
    return (
        <div
            className={styles.modal}
            onClick={(event) => {
                if (event.target.classList.contains('modal__blanker')) {
                    closeModal();
                }
            }}
        >
            <div className={styles.modalBlanker}>
                <div className={styles.modalContent}>
                    <button className={styles.modalCloseButton} onClick={() => closeModal()}><X /></button>
                    {children}
                </div>
            </div>
        </div>
    );
}
