import * as React from 'react';

function Mouth4(props) {
  return (
    <>
      <path
        d="M139.7 383.3s110.7 61.2 342-4.5c0 0-198.1 268.7-342 4.5z"
        fill="#010205"
      />
      <defs>
        <path
          id="prefix__a4"
          d="M139.7 383.3s110.7 61.2 342-4.5c0 0-198.1 268.7-342 4.5z"
        />
      </defs>
      <clipPath id="prefix__b4">
        <use xlinkHref="#prefix__a4" overflow="visible" />
      </clipPath>
      <path
        d="M21.3 292h65c25.1 0 42.1 25.5 32.4 48.7l-32.5 77.8c-12 28.8-52.8 28.8-64.8 0l-32.5-77.8c-9.7-23.2 7.3-48.7 32.4-48.7z"
        clipPath="url(#prefix__b4)"
        fill="#fff"
      />
      <path
        d="M135 292h65c25.1 0 42.1 25.5 32.4 48.7l-32.5 77.8c-12 28.8-52.8 28.8-64.8 0l-32.5-77.8c-9.7-23.2 7.3-48.7 32.4-48.7z"
        clipPath="url(#prefix__b4)"
        fill="#fff"
      />
      <path
        d="M248.7 292h65c25.1 0 42.1 25.5 32.4 48.7l-32.5 77.8c-12 28.8-52.8 28.8-64.8 0l-32.5-77.8c-9.7-23.2 7.3-48.7 32.4-48.7z"
        clipPath="url(#prefix__b4)"
        fill="#fff"
      />
      <path
        d="M362.4 292h65c25.1 0 42.1 25.5 32.4 48.7l-32.5 77.8c-12 28.8-52.8 28.8-64.8 0L330 340.6c-9.7-23.1 7.3-48.6 32.4-48.6z"
        clipPath="url(#prefix__b4)"
        fill="#fff"
      />
      <path
        d="M476.1 292h65c25.1 0 42.1 25.5 32.4 48.7L541 418.4c-12 28.8-52.8 28.8-64.8 0l-32.5-77.8C434 317.5 451 292 476.1 292z"
        clipPath="url(#prefix__b4)"
        fill="#fff"
      />
    </>
  );
}

const MemoMouth4 = React.memo(Mouth4);
export default MemoMouth4;
