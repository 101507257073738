import * as React from 'react';

function Mouth2(props) {
  return (
    <>
      <path
        d="M138.8 375.6s158.6-64.4 325.2 0c-.1 0-161.9 395.7-325.2 0z"
        fill="#010205"
      />
      <defs>
        <path
          id="prefix__a2"
          d="M138.8 375.6s158.6-64.4 325.2 0c-.1 0-161.9 395.7-325.2 0z"
        />
      </defs>
      <clipPath id="prefix__b2">
        <use xlinkHref="#prefix__a2" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#prefix__b2)"
        fill="#fff"
        d="M100.4 318.8h109.8l-54.9 131.3z"
      />
      <path
        clipPath="url(#prefix__b2)"
        fill="#fff"
        d="M173.6 318.8h109.8l-54.9 131.3z"
      />
      <path
        clipPath="url(#prefix__b2)"
        fill="#fff"
        d="M246.8 318.8h109.8l-54.9 131.3z"
      />
      <path
        clipPath="url(#prefix__b2)"
        fill="#fff"
        d="M320 318.8h109.8l-54.9 131.3z"
      />
      <path
        clipPath="url(#prefix__b2)"
        fill="#fff"
        d="M393.2 318.8H503l-54.9 131.3zM474.6 618H364.8l54.9-131.3z"
      />
      <path
        clipPath="url(#prefix__b2)"
        fill="#fff"
        d="M393.8 618H284l54.9-131.3z"
      />
      <path
        clipPath="url(#prefix__b2)"
        fill="#fff"
        d="M319.5 618.9L209.7 617l57.1-130.3z"
      />
    </>
  );
}

const MemoMouth2 = React.memo(Mouth2);
export default MemoMouth2;
