import * as React from 'react';

function Skin7(props) {
  const skin = props.skinsettings;
  return (
    <>
      <path
        d="M0 0v600h600V0H0zm578.3 413.2s-5.9-67.6-29.5-91.1c0 0 30.7 123.9 11.8 146.4 0 0-5.9-55.3-24.8-79.9 0 0 2.4 121.9-49.6 151.6 0 0 34.2-60.4 27.2-92.2-5.9 8.2-43.7 108.6-76.7 122.9 0 0 13-24.6 0-48.1 0 0-8.3 20.5-17.7 28.7 0 0 3.5-26.6-17.7-39.9 0 0 16.5 53.3 0 65.5 0 0-2.4-34.8-20.1-42 0 0 1.2 47.1-10.6 54.3 0 0-3.5-38.9-37.8-58.4 0 0 5.9 55.3-10.6 62.5 0 0 4.7-46.1-18.9-71.7 0 0-1.2 49.2-15.3 57.3 0 0-2.4-30.7-15.3-43 0 0-5.9 51.2-31.9 61.4 0 0 22.4-37.9 0-59.4 0 0-5.9 30.7-17.7 36.9 0 0-22.4-28.7-20.1-53.3 0 0-11.3 50.9 5.9 69.5.6.6 1 1 1.1 1.2-.4-.4-.8-.8-1.1-1.2-7.5-7.8-47.8-51.7-30.7-88.9 0 0-33.1 49.2-13 72.7 0 0-35.4-25.6-34.2-57.3 0 0-3.5 56.3 24.8 79.9 0 0-41.3-30.7-63.7-114.7 0 0 0 54.2 7.2 68 .7 1.1 1.1 1.7 1.1 1.7-.4-.5-.7-1-1.1-1.7-5.8-9.2-33.7-55.5-37.9-97.7.9 5.5-5.7 26.9-2.2 46.8 3.8 11 6.9 17.7 6.9 17.7-3.7-5.4-5.8-11.5-6.9-17.7-9.9-28.5-24.8-85.5-4.9-129-8.6 16.2-28.6 52.8-29.6 75.1 0 0-49.7-90.6-7.2-184.8 0 0 2.4 21.5 23 39.4 0 0-31.9-84-14.2-113.7 0 0 5.9 67.6 29.5 91.1 0 0-30.7-123.9-11.8-146.4 0 0 5.9 55.3 24.8 79.9 0 0-2.4-121.9 49.6-151.6 0 0-34.2 60.4-27.2 92.2 6-8.4 43.8-108.7 76.8-123.1 0 0-13 24.6 0 48.1 0 0 8.3-20.5 17.7-28.7 0 0-3.5 26.6 17.7 39.9 0 0-16.5-53.3 0-65.5 0 0 2.4 34.8 20.1 42 0 0-1.2-47.1 10.6-54.3 0 0 3.5 38.9 37.8 58.4 0 0-5.9-55.3 10.6-62.5 0 0-4.7 46.1 18.9 71.7 0 0 1.2-49.2 15.3-57.3 0 0 2.4 30.7 15.3 43 0 0 5.9-51.2 31.9-61.4 0 0-22.4 37.9 0 59.4 0 0 5.9-30.7 17.7-36.9 0 0 22.4 28.7 20.1 53.3 0 0 11.3-50.9-5.9-69.5-.6-.6-1-1-1.1-1.2.4.4.8.8 1.1 1.2 7.5 7.8 47.8 51.7 30.7 88.9 0 0 33.1-49.2 13-72.7 0 0 35.4 25.6 34.2 57.3 0 0 3.5-56.3-24.8-79.9 0 0 41.3 30.7 63.7 114.7 0 0 0-54.2-7.2-68-.7-1.1-1.1-1.7-1.1-1.7.4.5.7 1 1.1 1.7 5.8 9.2 33.7 55.5 37.9 97.7-.9-5.5 5.7-26.9 2.2-46.8-3.8-11-6.9-17.7-6.9-17.7 3.7 5.4 5.8 11.5 6.9 17.7 9.9 28.5 24.8 85.5 4.9 129 8.6-16 28.6-52.6 29.6-74.9 0 0 14.2 74.8-13 127 17.1-11.8 25.4-41.5 25.4-41.5s23.6 99.3-14.1 173.6z"
        opacity={skin.opacity}
        fill={skin.color}
      />
    </>
  );
}

const MemoSkin7 = React.memo(Skin7);
export default MemoSkin7;
