import React, { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './AcheivePop.module.scss';
import gem from '../img/gem.svg';

const AchievePop = ({ openModal, withNav }) => {
  const [popOpen, setPopOpen] = useState(true);
  const { t } = useTranslation(['shared']);

  return (
    <div
      className={cx(styles.achievePopContainer, {
        [styles.achievePopContainerWithNav]: withNav,
      })}
    >
      <div
        onClick={() => {
          openModal();
          setPopOpen(false);
        }}
        className={`${styles.achievePopHolder}${
          popOpen ? ` ${styles.popped}` : ''
        }`}
      >
        <div className={styles.achievePopContent}>
          <span className={styles.achievePopIcon}>
            <img alt="" src={gem} className={styles.achievePopGemIcon} />
          </span>
          <span className={styles.achievePopText}>
            {t('boom_gems_awarded')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AchievePop;
