import React from 'react';
import { useTranslation } from 'react-i18next';

export const Loader = ({ message }) => {
  const { t } = useTranslation(['shared']);
  return (
    <div className="loader">
      <p>{message || t('loading')}</p>
    </div>
  );
};
